// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-privacy-policy-card-2871f .app-privacy-policy-contentContainer-331e2{align-items:flex-start;display:flex;flex-wrap:wrap;gap:.6rem 1.5rem;justify-content:center;width:100%}.app-privacy-policy-card-2871f .app-privacy-policy-contentContainer-331e2 .app-privacy-policy-itemBox-69442{align-items:center;display:flex;flex-direction:column;justify-content:flex-start;width:1rem}.app-privacy-policy-card-2871f .app-privacy-policy-contentContainer-331e2 .app-privacy-policy-itemBox-69442 .app-privacy-policy-img-48a63{border-radius:.2rem;box-shadow:0 .1rem .2rem 0 rgba(0,0,0,.2);height:.8rem;width:.8rem}.app-privacy-policy-card-2871f .app-privacy-policy-contentContainer-331e2 .app-privacy-policy-itemBox-69442 .app-privacy-policy-title-5c5d0{color:#000;font-size:20px;font-weight:500;margin-top:.2rem;text-align:center}.app-privacy-policy-banner-common-text-37cc5{color:#fff}.app-privacy-policy-banner-common-text-37cc5 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.app-privacy-policy-banner-common-text-37cc5 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "app-privacy-policy-card-2871f",
	"contentContainer": "app-privacy-policy-contentContainer-331e2",
	"itemBox": "app-privacy-policy-itemBox-69442",
	"img": "app-privacy-policy-img-48a63",
	"title": "app-privacy-policy-title-5c5d0",
	"banner-common-text": "app-privacy-policy-banner-common-text-37cc5",
	"bannerCommonText": "app-privacy-policy-banner-common-text-37cc5"
};
export default ___CSS_LOADER_EXPORT___;
