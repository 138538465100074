// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/bg_yinsizhengce_1920.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/svg/icon_More_32.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".privacy-policy-card-e7443{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-text-05498{color:#fff;text-align:center}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07{align-items:auto;display:flex;justify-content:space-between;padding-top:.6rem}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07 .privacy-policy-btn-25e5e{border:.01rem solid #fff;border-radius:.02rem;cursor:pointer;display:inline-block;font-size:20px;font-weight:500;height:.64rem;line-height:36px;padding:.14rem .4rem;position:relative;text-align:left;width:5rem}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07 .privacy-policy-btn-25e5e:hover{background-color:hsla(0,0%,100%,.2)}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07 .privacy-policy-btn-25e5e:active{background-color:hsla(0,0%,100%,.3)}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07 .privacy-policy-btn-25e5e a{font-size:20px;font-weight:500;line-height:36px}.privacy-policy-card-e7443 .privacy-policy-contentContainer-27f3e .privacy-policy-lookBtnContainer-c4c07 .privacy-policy-btn-25e5e:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;content:\"\";height:.32rem;position:absolute;right:.4rem;top:.16rem;width:.32rem}.privacy-policy-banner-common-text-1fad8{color:#fff}.privacy-policy-banner-common-text-1fad8 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.privacy-policy-banner-common-text-1fad8 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "privacy-policy-card-e7443",
	"contentContainer": "privacy-policy-contentContainer-27f3e",
	"text": "privacy-policy-text-05498",
	"lookBtnContainer": "privacy-policy-lookBtnContainer-c4c07",
	"btn": "privacy-policy-btn-25e5e",
	"banner-common-text": "privacy-policy-banner-common-text-1fad8",
	"bannerCommonText": "privacy-policy-banner-common-text-1fad8"
};
export default ___CSS_LOADER_EXPORT___;
