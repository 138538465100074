// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/bg_anquanbaozhang_1920.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".data-life-card-dbf9b{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover}.data-life-card-dbf9b .data-life-contentContainer-52e03{align-items:auto;color:#fff;display:flex;justify-content:auto}.data-life-card-dbf9b .data-life-contentContainer-52e03 .data-life-img-3ac60{height:5rem;width:6.82rem}.data-life-banner-common-text-160f3{color:#fff}.data-life-banner-common-text-160f3 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.data-life-banner-common-text-160f3 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "data-life-card-dbf9b",
	"contentContainer": "data-life-contentContainer-52e03",
	"img": "data-life-img-3ac60",
	"banner-common-text": "data-life-banner-common-text-160f3",
	"bannerCommonText": "data-life-banner-common-text-160f3"
};
export default ___CSS_LOADER_EXPORT___;
