import ISOIEC27017Img from '@/assets/img/img_ISOIEC 27017.png';
import ISOIEC27001Img from '@/assets/img/img_ISOIEC 27001.png';
import ISOIEC22301Img from '@/assets/img/22301.png';
import ISOIEC37301Img from '@/assets/img/37301.png';
import ISOIEC27032Img from '@/assets/img/27032.png';
// import ISOIEC27040 from '@/assets/img/img_ISOIEC 27040.png';
import ICSAImg from '@/assets/img/csa2.png';
import ISOIEC27018Img from '@/assets/img/img_ISOIEC 27018.png';
import ISOIEC27701Img from '@/assets/img/img_ISOIEC 27701.png';
import ISOIEC29151Img from '@/assets/img/img_ISOIEC 29151.png';
// import DCMM from '@/assets/img/DCMM.png';
// import CCRC from '@/assets/img/CCRC.png';
// import g1 from '@/assets/img/g1.png';
import BSImg from '@/assets/img/img_BS 10012.png';
import PCIImg from '@/assets/img/img_PCI DSS.png';
import SOCImg from '@/assets/img/img_SOC.png';
import kexinyunImg from '@/assets/img/img_kexinyun.png';
// import dsmmImg from '@/assets/img/img_DSMM.png';
import anquandengjiImg from '@/assets/img/img_anquandengji.png';
// import gerenxinxiImg from '@/assets/img/img_gerenxinxi.png';
import shujuanquanguanliImg from '@/assets/complianceEnable/pic_DSM.png';
import C27001Img from '@/assets/img/certificate/C27001Img.png';
import C27017Img from '@/assets/img/certificate/C27017Img.png';
import CSAImg from '@/assets/img/certificate/CSAImg.png';
import ISO27032 from '@/assets/img/certificate/C27032.png';
import Canquan from '@/assets/img/certificate/Canquan.png';
import CDSM from '@/assets/img/certificate/CDSM.png';
import CPCI from '@/assets/img/certificate/CPCI.png';
import CISOIEC27018 from '@/assets/img/certificate/CISOIEC27018.png';
import CISOIEC29151 from '@/assets/img/certificate/CISOIEC29151.png';
import CISOIEC27701 from '@/assets/img/certificate/CISOIEC27701.png';
import CISOIEC27040 from '@/assets/img/certificate/CISOIEC27040.png';
import CBS10012 from '@/assets/img/certificate/CBS10012.png';
import ccg1 from '@/assets/img/certificate/ccg1.png';
import ccrcer from '@/assets/img/certificate/ccrcer.png';
import ccrcsm1 from '@/assets/img/certificate/ccrcsm1.png';
import ccrcsm3 from '@/assets/img/certificate/ccrcsm3.png';
import CDCMM from '@/assets/img/certificate/CDCMM.png';
import CISO22301 from '@/assets/img/certificate/CISO22301.png';
import CISO37301 from '@/assets/img/certificate/CISO37301.png';
import CISO42001 from '@/assets/img/certificate/CISO42001.png';
import CPIP from '@/assets/img/certificate/CPIP.png';
import CSOC1 from '@/assets/img/certificate/CSOC1.png';
import CSOC2 from '@/assets/img/certificate/CSOC2.png';
import CSOC3 from '@/assets/img/certificate/CSOC3.png';
import Ckexin from '@/assets/img/certificate/Ckexin.png';
import Cdjcp31 from '@/assets/img/certificate/Cdjcp31.png';
import Cdjcp32 from '@/assets/img/certificate/Cdjcp32.png';
import Cdjcp33 from '@/assets/img/certificate/Cdjcp33.png';
import Cdjcp4 from '@/assets/img/certificate/Cdjcp4.png';
import CAPP from '@/assets/img/certificate/CAPP.png';

export default [
    {
        tags: 'ISO 27001 信息安全管理体系认证',
        icon: ISOIEC27001Img,
        showApproveIcon: true,
        showInter: true,
        img: C27001Img,
        content: (
            <>
                ISO/IEC 27001是国际标准化组织制定的全球广泛认可的信息安全管理标准。
                它提供了一套全面的信息安全管理框架，涵盖人员、流程和技术等方面，帮助组织识别、评估和处理信息安全风险，保障信息资产的保密性、完整性和可用性。
                <br />
                百度获得该证书，证明了百度实践了信息安全管理体系的国际标准，建设了全面的信息安全管理框架。
            </>
        ),
    },
    {
        tags: 'ISO 27017 云服务信息安全管理体系认证',
        icon: ISOIEC27017Img,
        showApproveIcon: true,
        img: C27017Img,
        content: (
            <>
                ISO/IEC 27017 作为一项国际标准，为云服务提供商和云服务用户提供了关于信息安全控制的指导。
                <br />
                百度获得该证书，证明了百度建立完善的信息安全管理控制，具备信息资产的保护高水平，增强数据保护和隐私保护，确保云环境中的合规性。
            </>
        ),
    },
    {
        tags: 'ISO 27018 公有云个人隐私安全管理体系认证',
        icon: ISOIEC27018Img,
        showApproveIcon: true,
        img: CISOIEC27018,
        content: (
            <>
                ISO/IEC 27018针对公有云服务提供商，规定了在处理个人可识别信息（PII）时应遵循的隐私保护原则和控制措施。帮助公有云服务提供商在保护用户个人隐私方面达到国际认可的标准。
                <br />
                百度获得该证书，证明百度遵循国际公认的准则，具有相应的控制体系来专门处理客户的隐私保护问题，在维护客户个人信息安全方面持续努力。
            </>
        ),
    },
    {
        tags: 'ISO 27032 网络空间安全管理体系认证',
        icon: ISOIEC27032Img,
        showApproveIcon: true,
        img: ISO27032,
        content: (
            <>
                ISO/IEC 27032 聚焦于网络空间安全，为组织在网络空间中的安全管理提供指导。它强调应对网络攻击、保护网络资产、确保业务连续性等方面的措施，适用于各类面临网络安全威胁的组织。
                <br />
                百度获得该证书，证明了百度业已形成了一套系统化、科学化的网络安全管理框架，拥有健全的网络安全管理体系，具备网络安全防护能力和保障企业信息资产的安全的能力。
            </>
        ),
    },
    {
        tags: 'ISO 27040 数据存储安全管理体系认证',
        // icon: ISOIEC27040,
        icon: null,
        showApproveIcon: true,
        img: CISOIEC27040,
        content: (
            <>
                ISO/IEC 27040专注于数据存储方面的安全管理，提供了数据存储系统的安全控制要求和最佳实践。涵盖数据存储的设计、实施、操作和维护等各个阶段，确保数据在存储过程中的安全性。
                <br />
                百度获得该证书，证明百度为数据存储安全提供了详细的技术指导，通过采用经过验证的一致方法来计划、设计、和实施数据存储安全，以定义适当的风险缓解水平。
            </>
        ),
    },
    {
        tags: 'ISO 27701 个人隐私信息安全管理体系认证',
        // tags: 'ISO/IEC 27701',
        icon: ISOIEC27701Img,
        showApproveIcon: true,
        img: CISOIEC27701,
        content: (
            <>
                {/* <p>是在 ISO 27001 和 ISO 27002 基础上，专门针对隐私信息管理的扩展标准。它将隐私保护融入信息安全管理体系，帮助组织同时满足信息安全和隐私法规要求。</p> */}
                ISO/IEC
                27701 是一项国际管理系统标准体系，专注于个人信息管理体系和隐私信息管理体系，为组织提供管理个人信息和保护隐私的指导。
                <br />
                百度获得该证书，证明百度在隐私保护方面的流程、技术和人员管理等方面符合了该国际标准的要求，进一步证明百度个人隐私和数据安全保护能力处于全球领先地位。
            </>
        ),
    },
    {
        tags: 'ISO 29151 个人身份信息保护管理体系认证',
        icon: ISOIEC29151Img,
        showApproveIcon: true,
        img: CISOIEC29151,
        content: (
            <>
                ISO/IEC 29151是针对保护个人可识别信息（PII）风险和影响评估制定的国际标准，旨在确保个人信息得到妥善保护并减轻相关风险和影响。
                <br />
                百度获得该证书，证明百度在个人可识别信息保护方面已经具备了必要的措施和管理体系，确保为用户提供一个可信赖的安全环境，保障个人信息隐私和数据安全。
            </>
        ),
    },
    {
        tags: 'ISO 22301 业务连续性管理体系认证',
        icon: ISOIEC22301Img,
        showApproveIcon: true,
        img: CISO22301,
        content: (
            <>
                ISO/IEC 22301 帮助组织识别可能影响业务运营的潜在威胁，制定应对策略和恢复计划，
                确保在发生突发事件（如自然灾害、网络攻击等）时，组织能够快速恢复业务运营，减少损失，保障业务的连续性。
                <br />
                百度获得该认证，证明百度制定了一套一体化的管理流程计划，对潜在的灾难加以辨别分析，并确定可能发生的冲击对企业运作造成的威胁，提供了一个有效的管理机制来阻止或抵消这些威胁，减少灾难事件带来的损失。
            </>
        ),
    },
    {
        tags: 'ISO 37301 合规管理体系认证',
        icon: ISOIEC37301Img,
        showApproveIcon: true,
        img: CISO37301,
        content: (
            <>
                ISO 37301 为组织建立、实施、维护和改进合规管理体系提供了通用的要求和指南。帮助组织识别和评估合规风险，建立合规制度和流程，确保组织的运营活动符合法律法规和行业规范。
                <br />
                百度获得该认证，证明百度的合规管理能力具备了系统化方法，采用PDCA理念完整覆盖了合规管理体系建设、运行、维护和改进的全流程，
                基于合规治理原则为组织建立并运行合规管理体系、传播积极的合规文化提供了整套解决方案。
            </>
        ),
    },
    {
        tags: 'ISO 42001 人工智能管理体系认证',
        // icon: ISOIEC27040,
        icon: null,
        showApproveIcon: true,
        img: CISO42001,
        content: (
            <>
                ISO 42001:2023《人工智能 管理体系 要求及使用指南》是全球首个针对人工智能管理体系的国际标准，
                由国际标准化组织（ISO）发布。该标准为组织在人工智能全生命周期管理提供了全面的框架和指导，有助于组织更好地管理人工智能应用带来的机遇和风险。
                <br />
                百度获得该证书，证明百度建立、实施、和维护了人工智能管理系统（AIMS），并确保该系统的道德属性和透明度属性是可预见和领先的。
            </>
        ),
    },
    {
        tags: 'BS 10012 隐私信息管理体系认证',
        // tags: 'BS 10012',
        icon: BSImg,
        showApproveIcon: true,
        img: CBS10012,
        content: (
            // <>
            //     <p>
            //         是英国标准协会制定的隐私信息管理标准，为组织处理个人信息提供了全面的隐私管理框架，包括个人信息的收集、存储、使用和共享等方面的要求，有助于组织遵守隐私法规。
            //     </p>
            // </>
            <>
                BS
                10012 是由英国标准协会（BSI）发布的个人信息管理体系标准，标准为个人信息管理提供了一个符合欧盟通用数据保护条例（GDPR）原则的最佳实践框架。
                <br />
                百度获得该证书，证明百度已建立完善的个人信息保护体系，并在采用符合国际标准的最佳实践确保用户个人隐私和数据的安全性。
            </>
        ),
    },
    {
        tags: 'CSA-STAR国际云安全联盟云安全认证',
        icon: ICSAImg,
        showApproveIcon: true,
        img: CSAImg,
        content: (
            <>
                CSA-STAR是由国际云安全联盟（CSA）推出，用于评估云服务提供商的安全性。该认证包括自我评估、独立验证和持续监控等不同级别，有助于客户了解云服务提供商的安全能力和实践，增强对云服务的信任。
                <br />
                百度获得该证书，证明了百度智能云致力于提升云计算服务安全水平和管理策略，标志着百度智能云的安全管理和技术能力获得国际权威的认可，符合国际上最先进的云安全标准要求。
            </>
        ),
    },
    {
        // tags: 'PCI DSS支付卡行业数据安全标准认证',
        tags: 'PCI DSS',
        icon: PCIImg,
        showApproveIcon: true,
        img: CPCI,
        content: (
            // <>
            //     <p>
            //         由支付卡行业安全标准委员会制定，旨在保护支付卡数据安全。适用于所有处理、存储或传输支付卡信息的企业，要求企业采取一系列安全措施，如加密、访问控制、安全审计等，防止支付卡数据泄露和欺诈。
            //     </p>
            // </>
            <>
                PCI
                DSS是由全球支付卡行业的主要品牌组织共同制定的一项针对支付卡行业制定的数据安全国际标准，旨在保护持卡人数据的安全性，并确保商家在处理支付卡数据时采取适当的安全措施。
                <br />
                百度获得该证书，证明百度在保护持卡人数据和敏感验证信息方面符合全球标准，可保障持卡人数据的机密性、完整性、可用性。
            </>
        ),
    },
    {
        tags: 'AICPA SOC Reports',
        // tags: 'SOC',
        icon: SOCImg,
        showApproveIcon: true,
        imgList: [CSOC1, CSOC2, CSOC3],
        content: (
            <>
                SOC审计是由第三方审计机构根据美国注册会计师协会（AICPA）制定的标准进行的独立审计，旨在评估服务机构的信息安全管理和内部控制情况。SOC证书分为三个类型：SOC
                1、SOC 2和SOC 3。
                <br />
                百度已获得SOC三项审计报告，证明百度在信息安全管理方面的安全能力，可确保用户数据得到妥善的安全保护和处理。
            </>
        ),
    },
    // {
    //     tags: '网络安全等级保护',
    //     icon: anquandengjiImg,
    //     content: (
    //         <>
    //             网络安全等级保护是中国国家标准化管理委员会发布的一项重要安全标准，旨在规范和保护网络信息系统的安全，防范网络安全威胁和风险。
    //             <br />
    //             百度多个业务系统通过了等级保护备案和测评，证明百度履行《网络安全法》要求的企业责任以及百度在网络安全等级保护方面的实践和努力。
    //         </>
    //     ),
    // },
    {
        tags: '网络安全等级保护测评 四级',
        icon: anquandengjiImg,
        showApproveIcon: false,
        img: Cdjcp4,
        content: (
            <>
                网络安全等级保护是国家用于指导国内各企业单位进行网络安全建设的依据，是各行各业广泛遵循的通用安全标准。《中华人民共和国 网络安全法》第三章 第二十一条 规定 “国家实行网络安全等级保护制度”。
                <br />
                百度智能云关键region通过了网络安全等级保护四级测评。意味着可以为国家重要领域、涉及国家安全、国计民生的核心系统提供服务。
            </>
        ),
    },
    {
        tags: '网络安全等级保护测评 三级',
        icon: anquandengjiImg,
        showApproveIcon: false,
        imgList: [Cdjcp31, Cdjcp32, Cdjcp33],
        content: (
            <>
                网络安全等级保护是国家用于指导国内各企业单位进行网络安全建设的依据，是各行各业广泛遵循的通用安全标准。《中华人民共和国 网络安全法》第三章 第二十一条 规定 “国家实行网络安全等级保护制度”
                <br />
                百度智能云IaaS、PaaS、SaaS过了网络安全等级保护三级，意味着百度智能云在安全管理中心、安全计算环境、安全区域边界和安全通信网络等方面满足了国家的相关要求。
            </>
        ),
    },
    {
        tags: 'DSM数据安全管理认证',
        // tags: '数据安全管理认证（DSM）',
        icon: shujuanquanguanliImg,
        img: Canquan,
        content: (
            <>
                {/* 用于评估组织的数据安全管理能力，确保组织建立了有效的数据安全管理体系，能够对数据资产进行全面保护，防止数据泄露、篡改等安全事件。 */}
                数据安全管理认证是根据《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国个人信息保护法》《中华人民共和国认证认可条例》有关规定，
                国家市场监督管理总局、国家互联网信息办公室开展的国推认证，鼓励网络运营者通过认证方式规范网络数据处理活动，加强网络数据安全保护。
                <br />
                百度通过了数据安全管理认证，证明百度在数据安全能力方面具备了相当高的成熟度水平。
            </>
        ),
    },
    {
        tags: 'PIP个人信息保护认证',
        icon: null,
        showApproveIcon: false,
        img: CPIP,
        content: (
            <>
                个人信息保护认证是证明个人信息处理者在认证范围内开展的个人信息收集、存储、使用、加工、传输、提供、公开、删除以及跨境等处理活动符合认证依据标准要求的一种认证活动。
                <br />
                百度获得该认证，证明百度具备了第三方认可的隐私保护能力，确保个人信息处理活动符合法律法规要求，来增强用户对企业的信任。
            </>
        ),
    },
    {
        tags: 'DSMM数据安全能力成熟度四级认证',
        // tags: '数据安全能力成熟度认证（DSMM）',
        // icon: dsmmImg,
        icon: null,
        img: CDSM,
        content: (
            // <>
            //     <p>
            //         数据安全能力成熟度模型（DSMM）将数据安全能力分为五个等级，四级表示组织的数据安全管理已达到较高水平，
            //         具备完善的数据安全策略、流程和技术措施，能够有效应对复杂的数据安全挑战，实现数据安全的持续优化。
            //     </p>
            // </>
            <>
                数据安全能力成熟度模型（Data Security Capability Maturity
                Mode,简称DSMM）是一种衡量组织数据安全能力成熟度水平的国内标准。该模型将数据安全能力划分为五个级别，从一级到五级，用于评估组织在数据安全方面的成熟度。
                <br />
                百度通过了DSMM第四级认证，证明百度在数据安全能力方面具备了相当高的成熟度水平。作为国内唯一通过DSMM四级认证的企业，百度在数据安全管理方面展示了其卓越的实践和措施。
            </>
        ),
    },
    {
        tags: 'DCMM数据管理能力成熟度评估四级',
        icon: null,
        showApproveIcon: false,
        img: CDCMM,
        content: (
            <>
                数据管理能力成熟度模型（DCMM）将数据管理能力划分为五个等级，是我国首个数据管理领域的国家标准，旨在帮助企业利用先进的数据管理理念和方法，建立和评价自身数据管理能力。
                <br />
                百度通过该评估，证明百度数据管理处于量化管理阶段，能够通过数据驱动决策，对数据进行精准分析和有效利用，实现数据价值的最大化。
            </>
        ),
    },
    {
        tags: 'APP用户权益保护测试能力验证合格证书',
        icon: null,
        img: CAPP,
        content: (
            <>
                史宾格安全及隐私合规检测平台通过了中国信息通信研究院组织的2022年度移动互联网应用程序（APP）用户权益保护测试能力验证项目。
                <br />
                自2021年创办此项考核起，史宾格已连续两年获得该能力验证结果证书。证明了史宾格在用户权益保护方面的优秀实践和能力，为用户提供可信赖的安全及隐私合规检测服务。
            </>
        ),
    },
    {
        tags: '可信云服务用户数据保护能力评估',
        icon: kexinyunImg,
        img: Ckexin,
        content: (
            <>
                云服务数据保护能力评估是一项国内的可信云安全类专项评估，旨在评估云服务提供商对用户数据安全的保护能力。该评估机制涵盖了事前防范、事中保护和事后追溯三个层面的关键指标范围。
                <br />
                百度已通过可信云用户数据保护能力评估认证，证明了百度可为用户的数据安全提供可靠保障，确保用户的数据得到安全保护和处理。
            </>
        ),
    },
    {
        tags: '中国信息安全测评中心-云计算安全一级证书',
        // icon: g1,
        icon: null,
        showApproveIcon: false,
        img: ccg1,
        content: (
            <>
                信息安全服务资质是对信息系统安全服务的提供者的技术、资源、法律、管理等方面的资质和能力，以及其稳定性、可靠性进行评估，并依据公开的标准和程序，对其安全服务保障能力进行认定的过程。
                <br />
                百度获得资质，证明百度在云计算安全领域获得了中国信息安全测评中心的认可。
            </>
        ),
    },
    {
        tags: 'CCRC信息系统安全集成服务一级认证',
        icon: null,
        showApproveIcon: false,
        img: ccrcsm1,
        content: (
            <>
                信息系统安全集成服务是指从事计算机应用系统工程和网络系统工程的安全需求界定、安全设计、建设实施、安全保证的活动。
                信息系统安全集成包括在新建信息系统的结构化设计中考虑信息安全保证因素，从而使建设完成后的信息系统满足建设方或使用方的安全需求而开展的活动。
                <br />
                百度获得该认证，证明百度安全集成服务的能力在以下四个方面体现：基本资格、服务管理能力、服务技术能力和服务过程能力都达到了最高的一级等级。
            </>
        ),
    },
    {
        tags: 'CCRC信息系统安全运维服务三级认证',
        icon: null,
        showApproveIcon: false,
        img: ccrcsm3,
        content: (
            <>
                表明企业在信息系统安全运维服务方面达到了一定的专业水平，能够按照相关标准和要求，为客户的信息系统提供安全运维服务，保障系统的安全性和可靠性。
                <br />
                安全运维资质认证是对安全运维服务方的基本资格、管理能力、技术能力和安全运维过程能力等方面进行评价。安全运维服务资质级别是衡量服务提供方的安全运维服务资格和能力的尺度。
                <br />
                百度获得该认证，证明百度具备了通过技术设施安全评估，技术设施安全加固，安全漏洞补丁通告、安全事件响应以及信息安全运维咨询，协助客户的信息系统管理人员进行信息系统的安全运维工作的第三方认可。
            </>
        ),
    },
    {
        tags: 'CCRC信息安全应急处理服务三级认证',
        icon: null,
        showApproveIcon: false,
        img: ccrcer,
        content: (
            <>
                意味着企业有能力在信息系统发生安全事件时，迅速响应并采取有效的应急处理措施，减少事件对系统和业务的影响，恢复系统的正常运行。
                <br />
                信息安全应急处理服务资质认证是对应急处理服务提供方的基本资格、管理能力、技术能力和应急处理服务过程能力等方面进行评价。信息安全应急处理服务资质级别是衡量服务提供方应急处理服务资格和能力的尺度
                <br />
                百度获得该认证，证明百度能帮助客户实现通过制定应急计划使得影响网络与信息系统安全的安全事件能够得到及时响应，并在安全事件一旦发生后进行标识、记录、分类和处理，直到受影响的业务恢复正常运行的过程。
            </>
        ),
    },
    // {
    //     tags: 'CCRC信息安全风险评估服务 RA',
    //     // icon: ISOIEC27701Img,
    //     content: (
    //         <>
    //             <p>
    //                 企业具备对信息系统进行安全风险评估的专业能力，能够识别系统中存在的安全风险，评估风险的可能性和影响程度，并提出相应的风险应对建议，帮助客户降低信息安全风险。
    //             </p>
    //         </>
    //     ),
    // },
    // {
    //     tags: '个人信息保护影响评估（PIA）',
    //     icon: gerenxinxiImg,
    //     content: (
    //         <>
    //             根据《中华人民共和国个人信息保护法》规定，个人信息处理者应落实并记录个人信息保护影响评估。个人信息保护影响评估不仅是个人信息处理者履行合规义务的核心内容，也有助于提高社会对个人信息处理活动的信任度。
    //             <br />
    //             百度通过了CCIA的PIA评估，意味着百度在个人信息保护方面遵守了国家相关要求，切实保护了用户的合法权益，为用户提供了更可靠的数据保护和隐私安全保障。
    //         </>
    //     ),
    // },
];
