// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bai-pishu-card-cf20c{background-color:#fff;padding-bottom:1.2rem;padding-top:1.2rem}.bai-pishu-card-cf20c.bai-pishu-shiBinGe-9b384 img{margin:20px 0!important;width:90%!important}.bai-pishu-card-cf20c .titleContainer{margin-bottom:.32rem!important;margin-top:0!important}.bai-pishu-card-cf20c .titleContainer>div{margin-bottom:.16rem!important}.bai-pishu-card-cf20c .titleContainer>p{color:#8f96a9!important;font-size:14px!important;line-height:.2rem!important}.bai-pishu-card-cf20c p{text-indent:2em}.bai-pishu-card-cf20c .three{color:#000;font-weight:600;text-indent:0}.bai-pishu-banner-common-text-0a15e{color:#fff}.bai-pishu-banner-common-text-0a15e h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.bai-pishu-banner-common-text-0a15e p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "bai-pishu-card-cf20c",
	"shiBinGe": "bai-pishu-shiBinGe-9b384",
	"banner-common-text": "bai-pishu-banner-common-text-0a15e",
	"bannerCommonText": "bai-pishu-banner-common-text-0a15e"
};
export default ___CSS_LOADER_EXPORT___;
