// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".address-card-c5b74{background-color:#f1f7ff;color:#000;padding-bottom:.8rem;padding-top:.6rem}.address-card-c5b74 .address-contentContainer-76475 .address-title-28771{font-size:32px;font-weight:500;line-height:40px;margin-bottom:.4rem}.address-card-c5b74 .address-contentContainer-76475 .address-contentBox-d9c4e{align-items:flex-start;display:flex;justify-content:space-between;margin-bottom:.4rem}.address-card-c5b74 .address-contentContainer-76475 .address-contentBox-d9c4e .address-flexBox-c72ac{align-items:flex-start;display:flex;flex-direction:column;height:1.4rem;justify-content:space-between}.address-card-c5b74 .address-contentContainer-76475 .address-contentBox-d9c4e .address-flexBox-c72ac:last-child{width:61%}.address-card-c5b74 .address-contentContainer-76475 .address-contentBox-d9c4e .address-flexBox-c72ac div p{font-size:14px;line-height:28px}.address-card-c5b74 .address-contentContainer-76475 .address-img-63d01{width:100%}.address-banner-common-text-aeb11{color:#fff}.address-banner-common-text-aeb11 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.address-banner-common-text-aeb11 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "address-card-c5b74",
	"contentContainer": "address-contentContainer-76475",
	"title": "address-title-28771",
	"contentBox": "address-contentBox-d9c4e",
	"flexBox": "address-flexBox-c72ac",
	"img": "address-img-63d01",
	"banner-common-text": "address-banner-common-text-aeb11",
	"bannerCommonText": "address-banner-common-text-aeb11"
};
export default ___CSS_LOADER_EXPORT___;
