// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".privacy-policy-content-card-8c549{background-color:#fff;padding-bottom:.6rem;padding-top:1.2rem}.privacy-policy-content-card-8c549 .titleContainer{margin-bottom:.68rem!important;margin-top:0!important}.privacy-policy-content-banner-common-text-200cf{color:#fff}.privacy-policy-content-banner-common-text-200cf h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.privacy-policy-content-banner-common-text-200cf p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "privacy-policy-content-card-8c549",
	"banner-common-text": "privacy-policy-content-banner-common-text-200cf",
	"bannerCommonText": "privacy-policy-content-banner-common-text-200cf"
};
export default ___CSS_LOADER_EXPORT___;
