// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".children-privacy-card-26815{background-color:#fff;padding-bottom:1.2rem;padding-top:1.2rem}.children-privacy-card-26815 .titleContainer{margin-bottom:.68rem!important;margin-top:0!important}.children-privacy-banner-common-text-b7f49{color:#fff}.children-privacy-banner-common-text-b7f49 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.children-privacy-banner-common-text-b7f49 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "children-privacy-card-26815",
	"banner-common-text": "children-privacy-banner-common-text-b7f49",
	"bannerCommonText": "children-privacy-banner-common-text-b7f49"
};
export default ___CSS_LOADER_EXPORT___;
