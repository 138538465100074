// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".security-capability-card-c7045{background-color:#fff}.security-capability-card-c7045 .security-capability-contentContainer-71d4e .security-capability-text-8a4a5{color:#000;margin-bottom:.6rem;text-align:center}.security-capability-card-c7045 .security-capability-contentContainer-71d4e .security-capability-img-f7106{height:4.9rem}.security-capability-banner-common-text-cdf44{color:#fff}.security-capability-banner-common-text-cdf44 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.security-capability-banner-common-text-cdf44 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "security-capability-card-c7045",
	"contentContainer": "security-capability-contentContainer-71d4e",
	"text": "security-capability-text-8a4a5",
	"img": "security-capability-img-f7106",
	"banner-common-text": "security-capability-banner-common-text-cdf44",
	"bannerCommonText": "security-capability-banner-common-text-cdf44"
};
export default ___CSS_LOADER_EXPORT___;
