// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-root-f16a9{height:4.6rem;width:100%}.banner-root-f16a9 .banner-card-04fb7{background-position:50%;background-repeat:no-repeat;background-size:cover;height:100%;padding-bottom:1.8rem;padding-top:1.4rem}.banner-root-f16a9 .banner-card-04fb7 .banner-textContainer-df5e4{color:#fff;height:100%}.banner-root-f16a9 .banner-card-04fb7 .banner-textContainer-df5e4 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.banner-root-f16a9 .banner-card-04fb7 .banner-textContainer-df5e4 p{color:#e3e9f1;font-size:18px;line-height:30px}.banner-banner-common-text-96344{color:#fff}.banner-banner-common-text-96344 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.banner-banner-common-text-96344 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "banner-root-f16a9",
	"card": "banner-card-04fb7",
	"textContainer": "banner-textContainer-df5e4",
	"banner-common-text": "banner-banner-common-text-96344",
	"bannerCommonText": "banner-banner-common-text-96344"
};
export default ___CSS_LOADER_EXPORT___;
